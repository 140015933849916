import commonHelper from '@/app/utility/common.helper.utility';
import XLSX from 'xlsx';

export default {
  name: 'generateComparative',
  props: [
    'roundData',
    'roundProps',
    'itemBasis',
    'rfpHeaderId',
    'negotiationSequence'
  ],
  data() {
    return {
      generateComparativeData: [],
      generateComparativeFields: [
        {
          key: 'location_name',
          label: 'Location',
          class: 'text-center'
        },
        {
          key: 'cost_centre',
          class: 'text-center'
        },
        {
          key: 'item_code',
          label: 'Item Code',
          class: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'name',
          label: 'Vendor Name',
          class: 'text-center'
        },
        {
          key: 'negotiation_seq',
          label: 'Round',
          class: 'text-center'
        },
        {
          key: 'version',
          label: 'Version',
          class: 'text-center'
        },
        {
          key: 'unit_price',
          label: 'Price',
          class: 'text-center'
        },
        {
          key: 'attributes',
          label: 'Attributes',
          class: 'text-center'
        }
      ],
      locationName: {
        value: null,
        text: null
      },
      options: [],
      currentPage: 1,
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      rfpBasis: null,
      popoverContent: null
    };
  },
  watch: {
    currentPage: function() {
      this.getGenerateComparative();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGenerateComparative();
    }
  },
  mounted() {
    let locations = this.$store.state.rfp.rfpState;
    this.options = locations;
    this.rfpBasis = this.itemBasis;
  },
  methods: {
    // postGenerateComparative() {
    //   if (this.rfpBasis === 'BOQ') {
    //     let payload = {
    //       negotiation_hdr_id: this.roundProps.negotiation_hdr_id,
    //       rfp_header_id: this.roundProps.rfp_header_id
    //     };
    //     this.loading = true;
    //     this.$store
    //       .dispatch('rfp/postBoqGenerateComparative', payload)
    //       .then(response => {
    //         if (response.status === 200) {
    //           this.makeToast(response.data.message, 'success');
    //           let generateComparativeFields = response.data.data[0].comp_detail;
    //           generateComparativeFields = generateComparativeFields.split('|');
    //           for (let i of generateComparativeFields) {
    //             this.generateComparativeFields.push({
    //               key: i,
    //               class: 'wide-col text-center'
    //             });
    //           }
    //           this.generateComparativeData = response.data.data;
    //           this.getData(this.generateComparativeData);
    //         } else {
    //           this.makeToast(response.response.data.message, 'danger');
    //         }
    //       })
    //       .catch(() => {
    //         this.loading = false;
    //       });
    //   } else {
    //     let payload = {
    //       negotiation_hdr_id: this.roundProps.negotiation_hdr_id,
    //       rfp_header_id: this.roundProps.rfp_header_id
    //     };
    //     this.loading = true;
    //     this.$store
    //       .dispatch('rfp/postComparative', payload)
    //       .then(response => {
    //         if (response.status === 200) {
    //           this.makeToast(response.data.message, 'success');
    //           let generateComparativeFields = response.data.data[0].comp_detail;
    //           generateComparativeFields = generateComparativeFields.split('|');
    //           for (let i of generateComparativeFields) {
    //             this.generateComparativeFields.push({
    //               key: i,
    //               class: 'wide-col text-center'
    //             });
    //           }
    //           this.generateComparativeData = response.data.data;
    //           this.getData(this.generateComparativeData);
    //         } else {
    //           this.makeToast(response.response.data.message, 'danger');
    //         }
    //       })
    //       .catch(() => {
    //         this.loading = false;
    //       });
    //   }
    // },
    getData(inputData) {
      const names = inputData[0].comp_detail.split('|');
      const dataArray = inputData
        .slice(1)
        .map(obj => obj.comp_detail.split('|'));
      const result = dataArray.map(data => {
        return data.reduce((acc, value, index) => {
          acc[names[index]] = value;
          return acc;
        }, {});
      });
      this.generateComparativeData = result;
      let arr = [];
      for (let i of result) {
        for (let key in i) {
          if (key.includes('F_V') || key.includes('I_V')) {
            i[key] = +i[key];
          }
        }
      }
      for (let i of result) {
        let array = [];
        for (let key in i) {
          if (key.includes('F_V')) {
            array.push(i[key]);
          }
        }
        let smallest = Infinity;
        for (let j = 0; j < array.length; j++) {
          if (+array[j] < +smallest) {
            smallest = +array[j];
          }
        }
        arr.push(smallest);
      }

      // for (
      //   let i = 0, j = 0;
      //   i < this.generateComparativeData.length, j < arr.length;
      //   i++, j++
      // ) {
      //   for (let key in this.generateComparativeData[i]) {
      //     if (
      //       i == j &&
      //       key.includes('F_V') &&
      //       +this.generateComparativeData[i][key] === arr[j]
      //     ) {
      //       let cellVariantKey = key;
      //       this.$set(this.generateComparativeData[i], '_cellVariants', {
      //         [cellVariantKey]: 'success',
      //       });
      //     }
      //   }
      // }

      for (let i = 0; i < this.generateComparativeData.length; i++) {
        for (let key in this.generateComparativeData[i]) {
          if (key.includes('F_V')) {
            let cellValue = +this.generateComparativeData[i][key];
            if (cellValue === arr[i]) {
              let cellVariantKey = key;
              if (!this.generateComparativeData[i]['_cellVariants']) {
                this.$set(this.generateComparativeData[i], '_cellVariants', {});
              }
              this.$set(
                this.generateComparativeData[i]._cellVariants,
                cellVariantKey,
                'success'
              );
            }
          }
        }
      }
      setTimeout(() => {
        this.generateComparativeData = JSON.parse(
          JSON.stringify(this.generateComparativeData)
        );
      }, 0);
    },
    downloadExcelData() {
      this.makeToast('Excel Sheet downloading!', 'success');
      const ws = XLSX.utils.json_to_sheet(this.generateComparativeData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Comparative');
      XLSX.writeFile(wb, 'comparative.xlsx');
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast'
      });
    },
    getCellClass(value) {
      if (value === 'Red') return 'bg-danger';
      if (value === 'Green') return 'bg-success';
      if (value === 'Blue') return 'bg-primary';
      return '';
    },
    getGenerateComparative() {
      if (this.itemBasis === 'item') {
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          negotiation_seq: this.negotiationSequence
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/getRfpNewComparative', payload)
          .then(response => {
            if (response.status === 200) {
              let result = response.data.data;
              this.generateComparativeData = result;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (this.itemBasis === 'BOQ') {
        this.generateComparativeFields = [
          {
            key: 'location_name',
            label: 'Location',
            class: 'text-center'
          },
          {
            key: 'cost_centre',
            class: 'text-center'
          },
          {
            key: 'boq_line_code',
            label: 'BOQ Line Code',
            class: 'text-center'
          },
          {
            key: 'boq_quantity',
            label: 'Quantity',
            class: 'text-center'
          },
          {
            key: 'name',
            label: 'Vendor Name',
            class: 'text-center'
          },
          {
            key: 'negotiation_seq',
            label: 'Round',
            class: 'text-center'
          },
          {
            key: 'version',
            label: 'Version',
            class: 'text-center'
          },
          {
            key: 'unit_price',
            label: 'Price',
            class: 'text-center'
          },
          {
            key: 'attributes',
            label: 'Attributes',
            class: 'text-center'
          }
        ];
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          negotiation_seq: this.negotiationSequence
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/getRfpNewBoqComparative', payload)
          .then(response => {
            if (response.status === 200) {
              let result = response.data.data;
              this.generateComparativeData = result;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: 446
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    }
  },
  beforeDestroy() {
    this.eventBus.$off('state');
  }
};
