import addRequest from './addRequest';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../store';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';

export default {
  name: 'rfp',
  components: {
    addRequest,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication
  },
  data() {
    return {
      leName: {
        value: null,
        text: null
      },
      addRfpModal: false,
      rfpNumber: null,
      startDate: null,
      endDate: null,
      approvalStatus: null,
      rfpStatus: null,
      rfpData: [],
      rfpFields: [
        {
          key: 'rfp_number',
          label: 'RFP Number',
          class: 'col-fix text-center'
        },
        {
          key: 'start_date',
          class: 'col-fix text-center'
        },
        {
          key: 'end_date',
          class: 'col-fix text-center'
        },
        {
          key: 'approval_status',
          class: 'col-fix text-center'
        },
        {
          key: 'rfp_status',
          label: 'RFP Status',
          class: 'col-fix text-center'
        }
      ],
      showValueSetModal: false,
      vsetCode: null,
      parent_value_set_id: null,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      loading: false,
      currentDate: null,
      getRfpModal: false,
      rfpHeaderId: null,
      headerAllData: {},
      itemAllData: [],
      locationAllData: [],
      vendorAllData: [],
      negotiationAllData: [],
      userId: null,
      rfpStatusOptions: [
        { value: 'DRAFT', text: 'Draft' },
        { value: 'OPEN FOR BIDDING', text: 'Open For Bidding' },
        { value: 'CLOSED FOR BIDDING', text: 'Closed For Bidding' },
        { value: 'CLOSED', text: 'Closed' }
      ],
      boqAllData: [],
      selectedRfpDetails: false,
      showHistory: false,
      historyType: null,
      modalType: null,
      openkmAuthToken: null,
      selectedGridData: null,
      showDownloadModal: false,
      downloadCurrentPage: 1,
      downloadPerUploadPage: 10,
      downloadItems: [],
      totalUploadRows: null,
      showOpenKmModal: false,
      nfaHdrId: null,
      tncData: [],
      extendData: []
    };
  },
  watch: {
    currentPage: function() {
      this.getRfpGridData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRfpGridData();
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.openRfpModal();
        }
      }
    });
    const currentDate = new Date();
    this.currentDate = currentDate.toISOString().split('.')[0];
  },
  methods: {
    openShowRfpModal(flag) {
      this.addRfpModal = flag;
    },
    openShowGetRfpModal(flag) {
      this.getRfpModal = flag;
    },
    openRfpModal() {
      this.addRfpModal = true;
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.leName = {
          value: null,
          text: null
        };
      }
    },
    getRfpGridData() {
      let payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        rfp_number: this.rfpNumber ? this.rfpNumber : null,
        approval_status: this.approvalStatus ? this.approvalStatus : null,
        rfp_status: this.rfpStatus ? this.rfpStatus : null,
        start_date: this.startDate ? this.startDate : null,
        end_date: this.endDate ? this.endDate : null
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpGridData', payload)
        .then(response => {
          if (response.status === 200) {
            this.rfpData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clearSearchFilters() {
      this.rfpNumber = null;
      this.approvalStatus = null;
      this.rfpStatus = null;
      this.startDate = null;
      this.endDate = null;
    },
    rowSelected(data) {
      this.rfpHeaderId = data.rfp_header_id;
      this.userId = data.user_id;
      this.getAllRfpData();
    },
    clearDateTime(type) {
      if (type === 'startDateSpecific') {
        this.startDate = null;
        this.endDate = null;
      } else if (type === 'endDateSpecific') {
        this.endDate = null;
      }
    },
    getAllRfpData() {
      const payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpDataByHeaderId', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.headerAllData = {
              approval_status: result.approval_status,
              description: result.description,
              end_date: result.end_date,
              le_id: result.le_id,
              le_name: result.le_name,
              rfp_header_id: result.rfp_header_id,
              rfp_number: result.rfp_number,
              rfp_status: result.rfp_status,
              rfp_type: result.rfp_type,
              start_date: result.start_date,
              user_department: result.user_department,
              version: result.version,
              rfp_basis: result.rfp_basis
            };
            this.itemAllData = result.rfp_item_dtls;
            (this.locationAllData = result.rfp_location_dtls),
              (this.negotiationAllData = result.rfp_negotiation_dtls),
              (this.vendorAllData = result.rfp_vendor_dtls),
              (this.getRfpModal = true);
            this.tncData = result.rfp_tc_dtls;
            this.boqAllData = result.rfp_boq_dtls;
            this.extendData = result.rfp_ext_dtls;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getAllRfp(headerId) {
      this.rfpHeaderId = headerId;
      this.getAllRfpData();
    },
    clearValues(type) {
      if (type === 'rfpStatus') {
        this.rfpStatus = null;
      }
    }, 
    showHideHistory(flag, historyType) {
      // to click initiate approval than visible button
      if (
        (this.selectedRfpDetails.approval_status === 'INPROCESS' ||
          this.selectedRfpDetails.approval_status === 'NOTREQUIRED' ||
          this.selectedRfpDetails.approval_status === 'APPROVED') &&
        historyType === 'uploadDocument'
      ) {
        alert('You Cannot Upload Document in this Stage');
      } else {
        this.showHistory = flag;
        this.historyType = historyType;
      }
    },
    checkOpenKmPasswordSaved(modalType) {
      // to check password save or not
      this.modalType = modalType;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      // to get auth token for open km
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            if (this.modalType === 'DOWNLOAD') {
              this.downloadFiles(true);
            } else {
              this.selectedGridData = {
                ...this.selectedRfpDetails,
                nfa_cat_vset: null,
                nfa_cat: null,
                nfa_sub_cat_vset: null,
                nfa_sub_cat: null
              };
              this.showHideHistory(true, 'uploadDocument');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getUploadedDocDtl();
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.downloadCurrentPage - 1,
        _limit: this.downloadPerUploadPage,
        menu_id: store.state.shared.menuId
          ? store.state.shared.menuId
          : this.urlSummaryData.wf_menu_id,
        sub_menu_id: 0,
        record_id: this.nfaHdrId
          ? this.nfaHdrId
          : this.urlSummaryData.nfa_hdr_id
      };
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalUploadRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      // to open km modal
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      // to set token in km
      this.showHideOpenKmModal(false);
      if (this.modalType === 'DOWNLOAD') {
        this.downloadFiles(true);
      } else {
        this.openkmAuthToken = token;
        this.selectedGridData = {
          ...this.selectedRfpDetails,
          nfa_cat_vset: null,
          nfa_cat: null,
          nfa_sub_cat_vset: null,
          nfa_sub_cat: null
        };
        this.showHideHistory(true, 'uploadDocument');
      }
    },
    getRfpGridDataForCopyRfp() {
      let payload = {
        _limit: this.perPage,
        _page: 0,
        rfp_number: this.rfpNumber ? this.rfpNumber : null,
        approval_status: this.approvalStatus ? this.approvalStatus : null,
        rfp_status: this.rfpStatus ? this.rfpStatus : null,
        start_date: this.startDate ? this.startDate : null,
        end_date: this.endDate ? this.endDate : null
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpGridData', payload)
        .then(response => {
          if (response.status === 200) {
            this.addRfpModal = false;
            this.rfpData = [];
            this.rfpData.push(response.data.data.page[0]);
            this.rfpHeaderId = response.data.data.page[0].rfp_header_id;
            this.getAllRfpDataForCopyRfp();
            this.totalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getAllRfpDataForCopyRfp() {
      const payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpDataByHeaderId', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.headerAllData = {
              approval_status: result.approval_status,
              description: result.description,
              end_date: result.end_date,
              le_id: result.le_id,
              le_name: result.le_name,
              rfp_header_id: result.rfp_header_id,
              rfp_number: result.rfp_number,
              rfp_status: result.rfp_status,
              rfp_type: result.rfp_type,
              start_date: result.start_date,
              user_department: result.user_department,
              version: result.version,
              rfp_basis: result.rfp_basis
            };
            this.itemAllData = result.rfp_item_dtls;
            (this.locationAllData = result.rfp_location_dtls),
              (this.vendorAllData = result.rfp_vendor_dtls),
              (this.getRfpModal = true);
            this.boqAllData = result.rfp_boq_dtls;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
