import XLSX from 'xlsx';

export default {
  props: ['rfphdrid', 'itemBasis'],
  data() {
    return {
      roundOne: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorOne: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionOne: {
        version: null,
        bid_hdr_id: null
      },
      roundTwo: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorTwo: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionTwo: {
        version: null,
        bid_hdr_id: null
      },
      roundThree: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorThree: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionThree: {
        version: null,
        bid_hdr_id: null
      },
      roundFour: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorFour: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionFour: {
        version: null,
        bid_hdr_id: null
      },
      roundFive: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorFive: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionFive: {
        version: null,
        bid_hdr_id: null
      },
      roundSix: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorSix: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionSix: {
        version: null,
        bid_hdr_id: null
      },
      roundSeven: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorSeven: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionSeven: {
        version: null,
        bid_hdr_id: null
      },
      roundEight: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorEight: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionEight: {
        version: null,
        bid_hdr_id: null
      },
      roundNine: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorNine: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionNine: {
        version: null,
        bid_hdr_id: null
      },
      roundTen: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorTen: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionTen: {
        version: null,
        bid_hdr_id: null
      },
      roundEleven: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorEleven: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionEleven: {
        version: null,
        bid_hdr_id: null
      },
      roundTwelve: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorTwelve: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionTwelve: {
        version: null,
        bid_hdr_id: null
      },
      roundThirteen: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorThirteen: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionThirteen: {
        version: null,
        bid_hdr_id: null
      },
      roundFourteen: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorFourteen: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionFourteen: {
        version: null,
        bid_hdr_id: null
      },
      roundFifteen: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorFifteen: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionFifteen: {
        version: null,
        bid_hdr_id: null
      },
      roundSixteen: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorSixteen: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionSixteen: {
        version: null,
        bid_hdr_id: null
      },
      roundSeventeen: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorSeventeen: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionSeventeen: {
        version: null,
        bid_hdr_id: null
      },
      roundEighteen: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorEighteen: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionEighteen: {
        version: null,
        bid_hdr_id: null
      },
      roundNineteen: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorNineteen: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionNineteen: {
        version: null,
        bid_hdr_id: null
      },
      roundTwenty: {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      },
      vendorTwenty: {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      },
      versionTwenty: {
        version: null,
        bid_hdr_id: null
      },
      generateComparativeData: [],
      generateComparativeFields: [],
      showOpenRoundsModal: false,
      showOpenVendorsModal: false,
      showOpenVersionsModal: false,
      roundsData: [],
      roundsFields: [
        {
          key: 'negotiation_seq',
          label: 'Round',
          class: 'col-fix text-center'
        }
      ],
      rfpHeaderId: null,
      number: null,
      vendorsData: [],
      vendorsFields: [
        {
          key: 'name',
          class: 'col-fix text-center'
        }
      ],
      negotiationHeaderId: null,
      versionsData: [],
      versionsFields: [
        {
          key: 'version',
          class: 'col-fix text-center'
        }
      ],
      negotiationVendorId: null,
      payloadArray: [],
      numberArray: [],
      generatecomparativemodal: true,
      currentBlock: 1
    };
  },
  mounted() {
    if (this.rfphdrid) {
      this.rfpHeaderId = this.rfphdrid;
    }
    for (let i = 0; i < 20; i++) {
      this.payloadArray.push({
        bid_hdr_id: null
      });
    }
  },
  methods: {
    openRoundModal(number, num) {
      this.number = number;
      this.numberArray.push(num);
      this.getAllRfpData();
      this.showOpenRoundsModal = true;
    },
    roundModal(flag) {
      this.showOpenRoundsModal = flag;
    },
    openVendorModal(number) {
      if (number === 'one') {
        if (this.roundOne.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundOne.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'two') {
        if (this.roundTwo.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundTwo.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'three') {
        if (this.roundThree.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundThree.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'four') {
        if (this.roundFour.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundFour.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'five') {
        if (this.roundFive.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundFive.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'six') {
        if (this.roundSix.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundSix.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'seven') {
        if (this.roundSeven.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundSeven.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'eight') {
        if (this.roundEight.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundEight.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'nine') {
        if (this.roundNine.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundNine.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'ten') {
        if (this.roundTen.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundTen.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'eleven') {
        if (this.roundEleven.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundEleven.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'twelve') {
        if (this.roundTwelve.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundTwelve.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'thirteen') {
        if (this.roundThirteen.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundThirteen.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'fourteen') {
        if (this.roundFourteen.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundFourteen.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'fifteen') {
        if (this.roundFifteen.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundFifteen.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'sixteen') {
        if (this.roundSixteen.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundSixteen.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'seventeen') {
        if (this.roundSeventeen.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundSeventeen.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'eighteen') {
        if (this.roundEighteen.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundEighteen.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'nineteen') {
        if (this.roundNineteen.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundNineteen.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      } else if (number === 'twenty') {
        if (this.roundTwenty.negotiation_sequence === null) {
          alert('Please select round first!');
        } else {
          this.negotiationHeaderId = this.roundTwenty.negotiation_hdr_id;
          this.getVendorsData(this.negotiationHeaderId);
          this.showOpenVendorsModal = true;
        }
      }
    },
    vendorModal(flag) {
      this.showOpenVendorsModal = flag;
    },
    openVersionModal(number) {
      if (number === 'one') {
        if (this.vendorOne.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorOne.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorOne.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'two') {
        if (this.vendorTwo.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorTwo.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorTwo.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'three') {
        if (this.vendorThree.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorThree.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorThree.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'four') {
        if (this.vendorFour.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorFour.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorFour.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'five') {
        if (this.vendorFive.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorFive.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorFive.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'six') {
        if (this.vendorSix.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorSix.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorSix.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'seven') {
        if (this.vendorSeven.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorSeven.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorSeven.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'eight') {
        if (this.vendorEight.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorEight.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorEight.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'nine') {
        if (this.vendorNine.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorNine.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorNine.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'ten') {
        if (this.vendorTen.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorTen.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorTen.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'eleven') {
        if (this.vendorEleven.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorEleven.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorEleven.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'twelve') {
        if (this.vendorTwelve.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorTwelve.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorTwelve.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'thirteen') {
        if (this.vendorThirteen.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorThirteen.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorThirteen.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'fourteen') {
        if (this.vendorFourteen.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorFourteen.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorFourteen.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'fifteen') {
        if (this.vendorFifteen.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorFifteen.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorFifteen.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'sixteen') {
        if (this.vendorSixteen.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorSixteen.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorSixteen.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'seventeen') {
        if (this.vendorSeventeen.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorSeventeen.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorSeventeen.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'eighteen') {
        if (this.vendorEighteen.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorEighteen.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorEighteen.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'nineteen') {
        if (this.vendorNineteen.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorNineteen.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorNineteen.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      } else if (number === 'twenty') {
        if (this.vendorTwenty.name === null) {
          alert('Please select vendor first!');
        } else {
          this.negotiationHeaderId = this.vendorTwenty.negotiation_hdr_id;
          this.negotiationVendorId = this.vendorTwenty.negotiation_vendor_id;
          this.getVersionsData(
            this.negotiationHeaderId,
            this.negotiationVendorId
          );
          this.showOpenVersionsModal = true;
        }
      }
    },
    versionModal(flag) {
      this.showOpenVersionsModal = flag;
    },
    getAllRfpData() {
      const payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpDataByHeaderId', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data.rfp_negotiation_dtls;
            this.roundsData = result;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    roundSelected(item) {
      if (this.number === 'one') {
        this.roundOne = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[0].negotiation_hdr_id = this.roundOne.negotiation_hdr_id;
      } else if (this.number === 'two') {
        this.roundTwo = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[1].negotiation_hdr_id = this.roundTwo.negotiation_hdr_id;
      } else if (this.number === 'three') {
        this.roundThree = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[2].negotiation_hdr_id = this.roundThree.negotiation_hdr_id;
      } else if (this.number === 'four') {
        this.roundFour = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[3].negotiation_hdr_id = this.roundFour.negotiation_hdr_id;
      } else if (this.number === 'five') {
        this.roundFive = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[4].negotiation_hdr_id = this.roundFive.negotiation_hdr_id;
      } else if (this.number === 'six') {
        this.roundSix = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[5].negotiation_hdr_id = this.roundSix.negotiation_hdr_id;
      } else if (this.number === 'seven') {
        this.roundSeven = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[6].negotiation_hdr_id = this.roundSeven.negotiation_hdr_id;
      } else if (this.number === 'eight') {
        this.roundEight = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[7].negotiation_hdr_id = this.roundEight.negotiation_hdr_id;
      } else if (this.number === 'nine') {
        this.roundNine = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[8].negotiation_hdr_id = this.roundNine.negotiation_hdr_id;
      } else if (this.number === 'ten') {
        this.roundTen = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[9].negotiation_hdr_id = this.roundTen.negotiation_hdr_id;
      } else if (this.number === 'eleven') {
        this.roundEleven = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[10].negotiation_hdr_id = this.roundEleven.negotiation_hdr_id;
      } else if (this.number === 'twelve') {
        this.roundTwelve = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[11].negotiation_hdr_id = this.roundTwelve.negotiation_hdr_id;
      } else if (this.number === 'thirteen') {
        this.roundThirteen = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[12].negotiation_hdr_id = this.roundThirteen.negotiation_hdr_id;
      } else if (this.number === 'fourteen') {
        this.roundFourteen = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[13].negotiation_hdr_id = this.roundFourteen.negotiation_hdr_id;
      } else if (this.number === 'fifteen') {
        this.roundFifteen = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[14].negotiation_hdr_id = this.roundFifteen.negotiation_hdr_id;
      } else if (this.number === 'sixteen') {
        this.roundSixteen = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[15].negotiation_hdr_id = this.roundSixteen.negotiation_hdr_id;
      } else if (this.number === 'seventeen') {
        this.roundSeventeen = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[16].negotiation_hdr_id = this.roundSeventeen.negotiation_hdr_id;
      } else if (this.number === 'eighteen') {
        this.roundEighteen = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[17].negotiation_hdr_id = this.roundEighteen.negotiation_hdr_id;
      } else if (this.number === 'nineteen') {
        this.roundNineteen = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[18].negotiation_hdr_id = this.roundNineteen.negotiation_hdr_id;
      } else if (this.number === 'twenty') {
        this.roundTwenty = {
          negotiation_sequence: item.negotiation_seq,
          negotiation_hdr_id: item.negotiation_hdr_id
        };
        // this.payloadArray[19].negotiation_hdr_id = this.roundTwenty.negotiation_hdr_id;
      }
      this.showOpenRoundsModal = false;
    },
    getVendorsData(negotiationHeaderId) {
      let payload = {
        _page: 0,
        _limit: 50,
        negotiation_hdr_id: negotiationHeaderId,
        rfp_header_id: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getNegotiationRoundData', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            let result = response.data.data.page;
            this.vendorsData = result;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    vendorSelected(item) {
      if (this.number === 'one') {
        this.vendorOne = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[0].negotiation_vendor_id = this.vendorOne.negotiation_vendor_id;
      } else if (this.number === 'two') {
        this.vendorTwo = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[1].negotiation_vendor_id = this.vendorTwo.negotiation_vendor_id;
      } else if (this.number === 'three') {
        this.vendorThree = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[2].negotiation_vendor_id = this.vendorThree.negotiation_vendor_id;
      } else if (this.number === 'four') {
        this.vendorFour = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[3].negotiation_vendor_id = this.vendorFour.negotiation_vendor_id;
      } else if (this.number === 'five') {
        this.vendorFive = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[4].negotiation_vendor_id = this.vendorFive.negotiation_vendor_id;
      } else if (this.number === 'six') {
        this.vendorSix = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[5].negotiation_vendor_id = this.vendorSix.negotiation_vendor_id;
      } else if (this.number === 'seven') {
        this.vendorSeven = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[6].negotiation_vendor_id = this.vendorSeven.negotiation_vendor_id;
      } else if (this.number === 'eight') {
        this.vendorEight = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[7].negotiation_vendor_id = this.vendorEight.negotiation_vendor_id;
      } else if (this.number === 'nine') {
        this.vendorNine = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[8].negotiation_vendor_id = this.vendorNine.negotiation_vendor_id;
      } else if (this.number === 'ten') {
        this.vendorTen = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[9].negotiation_vendor_id = this.vendorTen.negotiation_vendor_id;
      } else if (this.number === 'eleven') {
        this.vendorEleven = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[10].negotiation_vendor_id = this.vendorEleven.negotiation_vendor_id;
      } else if (this.number === 'twelve') {
        this.vendorTwelve = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[11].negotiation_vendor_id = this.vendorTwelve.negotiation_vendor_id;
      } else if (this.number === 'thirteen') {
        this.vendorThirteen = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[12].negotiation_vendor_id = this.vendorThirteen.negotiation_vendor_id;
      } else if (this.number === 'fourteen') {
        this.vendorFourteen = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[13].negotiation_vendor_id = this.vendorFourteen.negotiation_vendor_id;
      } else if (this.number === 'fifteen') {
        this.vendorFifteen = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[14].negotiation_vendor_id = this.vendorFifteen.negotiation_vendor_id;
      } else if (this.number === 'sixteen') {
        this.vendorSixteen = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[15].negotiation_vendor_id = this.vendorSixteen.negotiation_vendor_id;
      } else if (this.number === 'seventeen') {
        this.vendorSeventeen = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[16].negotiation_vendor_id = this.vendorSeventeen.negotiation_vendor_id;
      } else if (this.number === 'eighteen') {
        this.vendorEighteen = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[17].negotiation_vendor_id = this.vendorEighteen.negotiation_vendor_id;
      } else if (this.number === 'nineteen') {
        this.vendorNineteen = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[18].negotiation_vendor_id = this.vendorNineteen.negotiation_vendor_id;
      } else if (this.number === 'twenty') {
        this.vendorTwenty = {
          name: item.name,
          negotiation_hdr_id: item.negotiation_hdr_id,
          negotiation_vendor_id: item.negotiation_vendor_id
        };
        // this.payloadArray[19].negotiation_vendor_id = this.vendorTwenty.negotiation_vendor_id;
      }
      this.showOpenVendorsModal = false;
    },
    versionSelected(item) {
      if (this.number === 'one') {
        this.versionOne = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[0].bid_hdr_id = this.versionOne.bid_hdr_id;
      } else if (this.number === 'two') {
        this.versionTwo = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[1].bid_hdr_id = this.versionTwo.bid_hdr_id;
      } else if (this.number === 'three') {
        this.versionThree = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[2].bid_hdr_id = this.versionThree.bid_hdr_id;
      } else if (this.number === 'four') {
        this.versionFour = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[3].bid_hdr_id = this.versionFour.bid_hdr_id;
      } else if (this.number === 'five') {
        this.versionFive = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[4].bid_hdr_id = this.versionFive.bid_hdr_id;
      } else if (this.number === 'six') {
        this.versionSix = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[5].bid_hdr_id = this.versionSix.bid_hdr_id;
      } else if (this.number === 'seven') {
        this.versionSeven = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[6].bid_hdr_id = this.versionSeven.bid_hdr_id;
      } else if (this.number === 'eight') {
        this.versionEight = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[7].bid_hdr_id = this.versionEight.bid_hdr_id;
      } else if (this.number === 'nine') {
        this.versionNine = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[8].bid_hdr_id = this.versionNine.bid_hdr_id;
      } else if (this.number === 'ten') {
        this.versionTen = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[9].bid_hdr_id = this.versionTen.bid_hdr_id;
      } else if (this.number === 'eleven') {
        this.versionEleven = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[10].bid_hdr_id = this.versionEleven.bid_hdr_id;
      } else if (this.number === 'twelve') {
        this.versionTwelve = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[11].bid_hdr_id = this.versionTwelve.bid_hdr_id;
      } else if (this.number === 'thirteen') {
        this.versionThirteen = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[12].bid_hdr_id = this.versionThirteen.bid_hdr_id;
      } else if (this.number === 'fourteen') {
        this.versionFourteen = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[13].bid_hdr_id = this.versionFourteen.bid_hdr_id;
      } else if (this.number === 'fifteen') {
        this.versionFifteen = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[14].bid_hdr_id = this.versionFifteen.bid_hdr_id;
      } else if (this.number === 'sixteen') {
        this.versionSixteen = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[15].bid_hdr_id = this.versionSixteen.bid_hdr_id;
      } else if (this.number === 'seventeen') {
        this.versionSeventeen = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[16].bid_hdr_id = this.versionSeventeen.bid_hdr_id;
      } else if (this.number === 'eighteen') {
        this.versionEighteen = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[17].bid_hdr_id = this.versionEighteen.bid_hdr_id;
      } else if (this.number === 'nineteen') {
        this.versionNineteen = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[18].bid_hdr_id = this.versionNineteen.bid_hdr_id;
      } else if (this.number === 'twenty') {
        this.versionTwenty = {
          version: item.version,
          bid_hdr_id: item.bid_hdr_id
        };
        this.payloadArray[19].bid_hdr_id = this.versionTwenty.bid_hdr_id;
      }
      this.showOpenVersionsModal = false;
    },
    getVersionsData(negotiationheaderid, negotiationvendorid) {
      let payload = {
        negotiation_hdr_id: negotiationheaderid,
        negotiation_vendor_id: negotiationvendorid
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getVersions', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            let result = response.data.data;
            this.versionsData = result;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getGenerateComparativeData() {
      let greatest = 0;
      for (let i = 0; i < this.numberArray.length; i++) {
        if (this.numberArray[i] > greatest) {
          greatest = this.numberArray[i];
        }
      }
      let payloadarray = [];
      for (let i = 0; i < greatest; i++) {
        payloadarray.push(this.payloadArray[i]);
      }
      let count = 0;
      for (let i of payloadarray) {
        if (
          i.negotiation_hdr_id === null ||
          i.negotiation_vendor_id === null ||
          i.version === null
        ) {
          count++;
        }
      }
      if (count > 0) {
        alert('Please fill in all the mandatory fields!');
      } else {
        if (this.itemBasis === 'item') {
          let payload = {
            rfp_header_id: this.rfpHeaderId,
            generate_comparative: {
              generate_comparative_list: payloadarray
            }
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/postGenerateComparative', payload)
            .then(response => {
              this.loading = false;
              if (response.status === 200) {
                let result = response.data.data;
                this.generateComparativeData = result;
                this.getData(this.generateComparativeData);
              } else {
                this.makeToast(response.response.data.message, 'danger');
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else if (this.itemBasis === 'BOQ') {
          let payload = {
            rfp_header_id: this.rfpHeaderId,
            generate_comparative: {
              boq_generate_comparative_list: payloadarray
            }
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/postGenerateComparativeBoq', payload)
            .then(response => {
              this.loading = false;
              if (response.status === 200) {
                let result = response.data.data;
                this.generateComparativeData = result;
                this.getData(this.generateComparativeData);
              } else {
                this.makeToast(response.response.data.message, 'danger');
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
    getData(inputData) {
      const names = inputData[0].comp_detail.split('|');
      const dataArray = inputData
        .slice(1)
        .map(obj => obj.comp_detail.split('|'));
      const result = dataArray.map(data => {
        return data.reduce((acc, value, index) => {
          acc[names[index]] = value;
          return acc;
        }, {});
      });
      this.generateComparativeData = result;
      let arr = [];
      for (let i of result) {
        let array = [];
        for (let key in i) {
          if (key.includes('Unit_Price')) {
            array.push(+(i[key]));
            i[key] = +(i[key])
          }
        }
        let smallest = Infinity;
        for (let j = 0; j < array.length; j++) {
          if (+array[j] < +smallest) {
            smallest = +array[j];
          }
        }
        arr.push(smallest);
      }
      for (let i = 0; i < this.generateComparativeData.length; i++) {
        for (let key in this.generateComparativeData[i]) {
          if (key.includes('Unit_Price')) {
            let cellValue = +this.generateComparativeData[i][key];
            if (cellValue === arr[i]) {
              let cellVariantKey = key;
              if (!this.generateComparativeData[i]['_cellVariants']) {
                this.$set(this.generateComparativeData[i], '_cellVariants', {});
              }
              this.$set(
                this.generateComparativeData[i]._cellVariants,
                cellVariantKey,
                'success'
              );
            }
          }
        }
      }
      setTimeout(() => {
        this.generateComparativeData = JSON.parse(
          JSON.stringify(this.generateComparativeData)
        );
      }, 0);
    },
    clearAllData() {
      this.roundOne = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorOne = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionOne = {
        version: null,
        bid_hdr_id: null
      };
      this.roundTwo = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorTwo = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionTwo = {
        version: null,
        bid_hdr_id: null
      };
      this.roundThree = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorThree = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionThree = {
        version: null,
        bid_hdr_id: null
      };
      this.roundFour = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorFour = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionFour = {
        version: null,
        bid_hdr_id: null
      };
      this.roundFive = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorFive = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionFive = {
        version: null,
        bid_hdr_id: null
      };
      this.roundSix = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorSix = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionSix = {
        version: null,
        bid_hdr_id: null
      };
      this.roundSeven = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorSeven = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionSeven = {
        version: null,
        bid_hdr_id: null
      };
      this.roundEight = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorEight = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionEight = {
        version: null,
        bid_hdr_id: null
      };
      this.roundNine = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorNine = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionNine = {
        version: null,
        bid_hdr_id: null
      };
      this.roundTen = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorTen = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionTen = {
        version: null,
        bid_hdr_id: null
      };
      this.roundEleven = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorEleven = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionEleven = {
        version: null,
        bid_hdr_id: null
      };
      this.roundTwelve = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorTwelve = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionTwelve = {
        version: null,
        bid_hdr_id: null
      };
      this.roundThirteen = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorThirteen = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionThirteen = {
        version: null,
        bid_hdr_id: null
      };
      this.roundFourteen = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorFourteen = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionFourteen = {
        version: null,
        bid_hdr_id: null
      };
      this.roundFifteen = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorFifteen = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionFifteen = {
        version: null,
        bid_hdr_id: null
      };
      this.roundSixteen = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorSixteen = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionSixteen = {
        version: null,
        bid_hdr_id: null
      };
      this.roundSeventeen = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorSeventeen = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionSeventeen = {
        version: null,
        bid_hdr_id: null
      };
      this.roundEighteen = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorEighteen = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionEighteen = {
        version: null,
        bid_hdr_id: null
      };
      this.roundNineteen = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorNineteen = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionNineteen = {
        version: null,
        bid_hdr_id: null
      };
      this.roundTwenty = {
        negotiation_sequence: null,
        negotiation_hdr_id: null
      };
      this.vendorTwenty = {
        name: null,
        negotiation_hdr_id: null,
        negotiation_vendor_id: null
      };
      this.versionTwenty = {
        version: null,
        bid_hdr_id: null
      };
      this.generateComparativeData = [];
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: "custom-toast",
      });
    },
    clearRounds(round) {
      if (round === 'roundOne') {
        this.roundOne = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorOne = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionOne = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundTwo') {
        this.roundTwo = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorTwo = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.VersionTwo = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundThree') {
        this.roundThree = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorThree = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionThree = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundFour') {
        this.roundFour = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorFour = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionFour = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundFive') {
        this.roundFive = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorFive = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionFive = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundSix') {
        this.roundSix = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorSix = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionSix = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundSeven') {
        this.roundSeven = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorSeven = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionSeven = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundEight') {
        this.roundEight = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorEight = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionEight = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundNine') {
        this.roundNine = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorNine = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionNine = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundTen') {
        this.roundTen = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorTen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionTen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundEleven') {
        this.roundEleven = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorEleven = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionEleven = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundTwelve') {
        this.roundTwelve = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorTwelve = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionTwelve = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundThirteen') {
        this.roundThirteen = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorThirteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionThirteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundFourteen') {
        this.roundFourteen = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorFourteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionFourteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundFifteen') {
        this.roundFifteen = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorFifteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionFifteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundSixteen') {
        this.roundSixteen = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorSixteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionSixteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundSeventeen') {
        this.roundSeventeen = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorSeventeen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionSeventeen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundEighteen') {
        this.roundEighteen = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorEighteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionEighteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundNineteen') {
        this.roundNineteen = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorNineteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionNineteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (round === 'roundTwenty') {
        this.roundTwenty = {
          negotiation_sequence: null,
          negotiation_hdr_id: null
        };
        this.vendorTwenty = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionTwenty = {
          version: null,
          bid_hdr_id: null
        };
      }
    },
    clearVendor(vendor) {
      if (vendor === 'vendorOne') {
        this.vendorOne = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionOne = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorTwo') {
        this.vendorTwo = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.VersionTwo = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorThree') {
        this.vendorThree = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionThree = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorFour') {
        this.vendorFour = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionFour = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorFive') {
        this.vendorFive = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionFive = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorSix') {
        this.vendorSix = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionSix = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorSeven') {
        this.vendorSeven = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionSeven = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorEight') {
        this.vendorEight = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionEight = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorNine') {
        this.vendorNine = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionNine = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorTen') {
        this.vendorTen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionTen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorEleven') {
        this.vendorEleven = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionEleven = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorTwelve') {
        this.vendorTwelve = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionTwelve = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorThirteen') {
        this.vendorThirteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionThirteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorFourteen') {
        this.vendorFourteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionFourteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorFifteen') {
        this.vendorFifteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionFifteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorSixteen') {
        this.vendorSixteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionSixteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorSeventeen') {
        this.vendorSeventeen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionSeventeen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorEighteen') {
        this.vendorEighteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionEighteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorNineteen') {
        this.vendorNineteen = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionNineteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (vendor === 'vendorTwenty') {
        this.vendorTwenty = {
          name: null,
          negotiation_hdr_id: null,
          negotiation_vendor_id: null
        };
        this.versionTwenty = {
          version: null,
          bid_hdr_id: null
        };
      }
    },
    clearVersion(version) {
      if (version === 'versionOne') {
        this.versionOne = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionTwo') {
        this.VersionTwo = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionThree') {
        this.versionThree = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionFour') {
        this.versionFour = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionFive') {
        this.versionFive = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionSix') {
        this.versionSix = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionSeven') {
        this.versionSeven = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionEight') {
        this.versionEight = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionNine') {
        this.versionNine = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionTen') {
        this.versionTen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionEleven') {
        this.versionEleven = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionTwelve') {
        this.versionTwelve = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionThirteen') {
        this.versionThirteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionFourteen') {
        this.versionFourteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionFifteen') {
        this.versionFifteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionSixteen') {
        this.versionSixteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionSeventeen') {
        this.versionSeventeen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionEighteen') {
        this.versionEighteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionNineteen') {
        this.versionNineteen = {
          version: null,
          bid_hdr_id: null
        };
      } else if (version === 'versionTwenty') {
        this.versionTwenty = {
          version: null,
          bid_hdr_id: null
        };
      }
    },
    downloadExcelData() {
      this.makeToast('Excel Sheet downloading!', 'success');
      const ws = XLSX.utils.json_to_sheet(this.generateComparativeData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Comparative');
      XLSX.writeFile(wb, 'comparative.xlsx');
    },
    getAllComparativeData() {
      if (this.itemBasis === 'item') {
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          negotiation_seq: null
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/getRfpNewComparative', payload)
          .then(response => {
            this.loading = false;
            if (response.status === 200) {
              let result = response.data.data;
              this.generateComparativeData = result;
              this.getData(this.generateComparativeData);
            } else {
              this.makeToast(response.response.data.message, 'danger');
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (this.itemBasis === 'BOQ') {
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          negotiation_seq: null
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/getRfpNewBoqComparative', payload)
          .then(response => {
            this.loading = false;
            if (response.status === 200) {
              let result = response.data.data;
              this.generateComparativeData = result;
              this.getData(this.generateComparativeData);
            } else {
              this.makeToast(response.response.data.message, 'danger');
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    addBlock() {
      this.currentBlock = this.currentBlock + 1;
    },
    removeLastBlock() {
      if (this.currentBlock >= 2) {
        this.currentBlock = this.currentBlock - 1;
      }
    }
  }
};
