import boqAttributes from './boqAttributes';
import uploadExcel from '@/app/components/shared/uploadExcel';
import URL_UTILITY from '../../../../utility/url.utility';

export default {
  name: 'boq',
  props: ['rfphdrid', 'headerAllData', 'boqAllData', 'negotiationAllData'],
  components: {
    boqAttributes,
    uploadExcel
  },
  data() {
    return {
      boqLineCode: null,
      description: null,
      quantity: null,
      uom: null,
      loading: false,
      showOpenBoqAttributeModal: false,
      boqData: [
        {
          boq_line_code: null,
          description: null,
          quantity: null,
          uom: null,
          boq_id: 0,
          delete_flag: false
        }
      ],
      boqFields: [
        {
          key: 'add_row',
          label: 'Add',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'remove_row',
          label: 'Remove',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'boq_line_code',
          label: 'BOQ Line Code',
          class: 'text-center'
        },
        {
          key: 'Description',
          class: 'text-center'
        },
        {
          key: 'quantity',
          class: 'text-center'
        },
        {
          key: 'uom',
          label: 'UOM',
          class: 'text-center'
        },
        {
          key: 'delete_flag',
          label: 'Delete',
          class: 'text-center'
        },
        {
          key: 'add_attribute',
          label: 'Add Attribute',
          class: 'text-center'
        }
      ],
      rfpHeaderId: null,
      responseMsg: null,
      editMode: true,
      rfpInitiateBidFlag: false,
      itemBasis: null,
      boqId: null,
      rfpType: null,
      rfpClosedFlag: false,
      rfpBoqFlag: false,
      showUploadExcel: false,
      boqIndex: null,
      downloadRfpItemDocumentUrl: URL_UTILITY.getRfpBoqSampleFile,
      popoverContent: null
    };
  },
  mounted() {
    if (this.rfphdrid) {
      this.rfpHeaderId = this.rfphdrid;
      // this.getBoqData();
    }
    if (this.boqAllData && this.boqAllData.length > 0) {
      this.rfpBoqFlag = true;
      this.editMode = false;
    } else {
      this.rfpBoqFlag = false;
    }
    this.eventBus.$emit('rfpBoqFlag', this.rfpBoqFlag);
    if (this.headerAllData) {
      this.itemBasis = this.headerAllData.rfp_basis;
      if (this.itemBasis === 'item') {
        this.rfpInitiateBidFlag = true;
        this.editMode = false;
      }
    }
    if (this.negotiationAllData && this.negotiationAllData.length > 0) {
      this.rfpClosedFlag = true;
    }
  },
  created() {
    this.eventBus.$on('rfpInitiateBidFlag', rfpInitiateBidFlag => {
      this.rfpInitiateBidFlag = rfpInitiateBidFlag;
    });
    this.eventBus.$on('itemBasis', itemBasis => {
      this.itemBasis = itemBasis;
      if (this.itemBasis === 'item') {
        this.rfpInitiateBidFlag = true;
        this.editMode = false;
      } else if (this.itemBasis === 'BOQ') {
        this.rfpInitiateBidFlag = false;
      }
    });
    this.eventBus.$on('boqAttributeDataFlag', boqAttributeDataFlag => {
      if (boqAttributeDataFlag) {
        this.$emit('getHeaderData');
      }
    });
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpHeaderId = rfpHeaderId;
    });
    this.eventBus.$on('rfpType', rfpType => {
      this.rfpType = rfpType;
    });
    this.eventBus.$on('rfpClosedFlag', rfpClosedFlag => {
      this.rfpClosedFlag = rfpClosedFlag;
    });
  },
  methods: {
    addNewRow() {
      // this function adds a new row on click of add button inside the table
      this.boqData.push({
        boq_line_code: null,
        Description: null,
        quantity: null,
        uom: null,
        boq_id: 0,
        delete_flag: false
      });
    },
    removeRow(index) {
      // this function removes the row on click of add button inside the tables
      this.boqData.splice(index, 1);
    },
    postBoqData() {
      let boqdetails = [];
      for (let i of this.boqData) {
        if (
          i.quantity === null &&
          (this.rfpType === 'NRC_OE' || this.rfpType === 'NRC_CE')
        ) {
          alert('Please select quantity');
        } else {
          boqdetails.push({
            boq_id: i.boq_id,
            boq_line_code: i.boq_line_code,
            boq_description: i.Description,
            boq_quantity: i.quantity,
            boq_uom: i.uom,
            delete_flag: i.delete_flag
          });
        }
      }
      let payload = {
        rfp_header_id: this.rfpHeaderId,
        rfp_boq_dtls: boqdetails
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/postBoqDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.getBoqData();
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    getBoqData() {
      const payload = {
        header_id: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getBoqDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.boqData = response.data.data;
            if (this.boqData.length === 0) {
              this.boqData.push({
                boq_line_code: null,
                Description: null,
                quantity: null,
                uom: null,
                boq_id: 0,
                delete_flag: false
              });
              this.rfpBoqFlag = false;
            } else {
              this.rfpBoqFlag = true;
            }
            this.editMode = false;
            this.eventBus.$emit('rfpBoqFlag', this.rfpBoqFlag);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: "custom-toast",
      });
    },
    alterEditMode() {
      this.editMode = !this.editMode;
    },
    deleteItem(item) {
      item.delete_flag = !item.delete_flag;
    },
    boqAttributeModal(flag) {
      this.showOpenBoqAttributeModal = flag;
    },
    openAttributesModal(id, index) {
      if (id === 0) {
        alert('Please save BOQ first!');
      } else {
        this.boqId = id;
        this.boqIndex = index;
        this.showOpenBoqAttributeModal = true;
      }
    },
    qtyTwoDecimalValue(index) {
      let quantity = this.boqData[index].quantity.toString().split('.');
      if (quantity.length > 1 && quantity[1].length > 2) {
        this.boqData[index].quantity = parseFloat(
          this.boqData[index].quantity
        ).toFixed(2);
        alert('Only Four Decimal Value Allowed.');
      }
    },
    showHideUploadExcel(flag) {
      this.showUploadExcel = flag;
    },
    closeUploadExcelModal() {
      this.getBoqData();
      this.showUploadExcel = false;
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: 446
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    }
  },
  beforeDestroy() {
    this.eventBus.$off('rfpBoqFlag');
  }
};
