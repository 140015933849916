import appStrings from '@/app/utility/string.utility';
import responseModal from '../../../shared/responseModal';
import commonHelper from '@/app/utility/common.helper.utility';

export default {
  props: ['locationAllData', 'rfphdrid'],
  components: {
    responseModal
  },
  data() {
    return {
      loading: false,
      country: {
        value: null,
        text: null
      },
      state: [],
      rfpState: [],
      showValueSetModal: false,
      vsetCode: null,
      parent_value_set_id: null,
      lessorCountry: {
        value: '100',
        text: 'India'
      },
      lessorState: {
        value: null,
        text: null
      },
      lessorCity: null,
      lessorPincode: null,
      region: [],
      rfpHeaderId: null,
      editMode: true,
      showOpenRegionModal: false,
      regionData: [
        {
          region: 'East'
        },
        {
          region: 'West'
        },
        {
          region: 'North'
        },
        {
          region: 'South'
        }
      ],
      regionFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'region',
          class: 'col-fix text-center'
        }
      ],
      selectAllRegion: false,
      rfpLocationFlag: false,
      rfpInitiateBidFlag: false,
      rfpClosedFlag: false,
      locationData: [
        {
          country_id: '100',
          country: 'India',
          region: null,
          state: null,
          location_id: 0,
          cost_centre: null
        }
      ],
      locationFields: [
        {
          key: 'add_row',
          label: 'Add',
          stickyColumn: true,
          class: 'col-fix-small text-center'
        },
        {
          key: 'remove_row',
          label: 'Remove',
          stickyColumn: true,
          class: 'col-fix-small text-center'
        },
        {
          key: 'country',
          class: 'col-fix text-center'
        },
        {
          key: 'region',
          class: 'col-fix text-center'
        },
        {
          key: 'state',
          class: 'col-fix text-center'
        },
        {
          key: 'cost_centre',
          class: 'col-fix text-center'
        }
      ],
      index: null,
      rfpStatesModal: false,
      rfpStatesData: [],
      rfpStatesFields: [
        {
          key: 'region',
          class: 'col-fix text-center'
        },
        {
          key: 'state',
          class: 'col-fix text-center'
        },
        // {
        //   key: 'state_id',
        //   class: 'col-fix text-center'
        // },
        {
          key: 'state_tax_code',
          class: 'col-fix text-center'
        }
      ],
      showOpenRfpStates: false,
      popoverContent: null,
      showOpenCostCentreModal: false,
      costCentreData: [],
      costCentreFields: [
        {
          key: 'state_code'
        },
        {
          key: 'cost_centre'
        }
      ],
      costCentreTotalRows: null,
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      costCentreCurrentPage: 1,
      countryIndex: null
    };
  },
  watch: {
    costCentreCurrentPage: function() {
      this.getCostCentreData();
    },
    perPage: function() {
      this.costCentreCurrentPage = 1;
      this.getCostCentreData();
    }
  },
  created() {
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpHeaderId = rfpHeaderId;
    });
    this.eventBus.$on('rfpInitiateBidFlag', rfpInitiateBidFlag => {
      this.rfpInitiateBidFlag = rfpInitiateBidFlag;
    });
    this.eventBus.$on('rfpClosedFlag', rfpClosedFlag => {
      this.rfpClosedFlag = rfpClosedFlag;
    });
    this.eventBus.$on('rfpSelectedState', rfpStateResponse => {
      this.locationData[this.index].region = rfpStateResponse.region;
      this.locationData[this.index].state = rfpStateResponse.state;
    });
    this.eventBus.$on('rfpSelectedCostCentre', rfpSelectedCostCentre => {
      this.locationData[this.index].cost_centre =
        rfpSelectedCostCentre.cost_centre;
    });
  },
  mounted() {
    this.rfpHeaderId = this.rfphdrid;
    if (this.locationAllData) {
      this.getParentToChildData(this.locationAllData);
      this.rfpLocationFlag = true;
      this.eventBus.$emit('rfpLocationFlag', this.rfpLocationFlag);
      this.editMode = false;
    }
    if (this.rfpHeaderId) {
      this.getLocationDetails();
    }
  },
  methods: {
    openValueSetModal(vsetCode, index) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.index = index;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.RFP_STATE ||
        this.vsetCode === appStrings.VALUESETTYPE.RFP_REGION
      ) {
        this.parent_value_set_id = this.lessorCountry.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_COUNTRY) {
        this.lessorCountry.text = item.value_code;
        this.lessorCountry.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RFP_STATE) {
        this.locationData[this.index].state = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RFP_REGION) {
        this.locationData[this.index].region = item.value_code;
      }
      this.showValueSetModal = false;
    },
    postLocationDetailsData() {
      let count = 0;
      for (let i = 0; i < this.locationData.length; i++) {
        if (
          this.locationData[i].country === 'India' &&
          this.locationData[i].state === null &&
          this.locationData[i].region === null &&
          this.locationData[i].cost_centre === null
        ) {
          count++;
          this.countryIndex = i;
        }
      }

      if (count > 1) {
        alert('RFP already exist for India');
        this.locationData.splice(this.countryIndex, 1);
      } else {
        let locationDetails = [];
        let payload = {};
        for (let i of this.locationData) {
          locationDetails.push({
            location_id: i.location_id,
            country: 'India',
            country_id: '100',
            region: i.region,
            state: i.state,
            cost_centre: i.cost_centre
          });
        }
        payload = {
          rfp_header_id: this.rfpHeaderId,
          rfp_location_dtls: locationDetails
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/postLocationDetails', payload)
          .then(response => {
            if (response.status === 200) {
              this.responseMsg = response.data.message;
              this.makeToast(this.responseMsg, 'success');
              this.getLocationDetails();
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'danger');
            }
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            this.makeToast(err.message, 'danger');
          });
      }
    },

    // for (let i of this.locationData) {
    //   if (i.region === null && i.state.length === 0) {
    //     locationDetails.push({
    //       location_id: 0,
    //       country: 'India',
    //       country_id: '100',
    //       region: null,
    //       state: null
    //     });
    //   } else if (i.state.length === 0 && i.region !== null) {
    //     locationDetails.push({
    //       location_id: 0,
    //       country: 'India',
    //       country_id: '100',
    //       region: i.region,
    //       state: null
    //     });
    //   } else if (i.state.length !== 0 && i.region === null) {
    //     for (let j of i.state) {
    //       locationDetails.push({
    //         location_id: 0,
    //         country: 'India',
    //         country_id: '100',
    //         region: null,
    //         state: j
    //       });
    //     }
    //   } else if (i.state.length !== 0 && i.region !== null) {
    //     for (let j = 0; j < i.state.length; j++) {
    //       locationDetails.push({
    //         location_id: 0,
    //         country: 'India',
    //         country_id: '100',
    //         region: i.region,
    //         state: i.state[j]
    //       });
    //     }
    //   }
    // }

    getLocationDetails() {
      const payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getLocationDetails', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.locationData = result;
            if (this.locationData.length === 0) {
              this.locationData.push({
                country_id: '100',
                country: 'India',
                region: null,
                state: null,
                location_id: 0,
                cost_centre: null
              });
            }
            this.$store.commit('rfp/setStateRfp', this.state);
            this.rfpLocationFlag = true;
            this.eventBus.$emit('rfpLocationFlag', this.rfpLocationFlag);
            this.editMode = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast'
      });
    },
    getParentToChildData(data) {
      this.lessorCountry = {
        value: '100',
        text: 'India'
      };
      if (data.length === 0) {
        this.locationData.push({
          country_id: '100',
          country: 'India',
          region: null,
          state: null,
          location_id: 0,
          cost_centre: null
        });
      } else {
        for (let i = 0; i < data.length; i++) {
          this.region.push(data[i].region);
          this.state.push(data[i].state);
          this.rfpState.push({
            value: data[i].location_id,
            text: data[i].state
          });
        }
      }
      this.$store.commit('rfp/setStateRfp', this.rfpState);
    },
    alterEditMode() {
      this.editMode = !this.editMode;
    },
    regionModal(flag) {
      this.showOpenRegionModal = flag;
    },
    openRegionModal() {
      this.showOpenRegionModal = true;
    },
    selectRegionChecked(flag, index, item) {
      this.regionData[index].select = flag;
      if (flag) {
        this.region.push(item.region);
      } else {
        this.region = this.region.filter(el => el !== item.region);
      }
    },
    addNewRow() {
      this.locationData.push({
        country_id: '100',
        country: 'India',
        region: null,
        state: null,
        location_id: 0,
        cost_centre: null
      });
    },
    removeRow(index) {
      this.locationData.splice(index, 1);
    },
    clearRegion(index) {
      this.locationData[index].region = null;
    },
    clearState(index) {
      this.locationData[index].state = null;
    },
    openCloseResponseModal(item, region, index) {
      this.index = index;
      if (item === 'RFP_STATES') {
        this.index = index;
        this.showOpenRfpStates = true;
        this.getRfpStatesData(region);
      } else if (item === 'COST_CENTRE') {
        this.showOpenCostCentreModal = true;
        // this.getCostCentreData();
      }
    },
    closeRfpStatesModal(flag) {
      this.showOpenUserDepartment = flag;
    },
    getRfpStatesData(region) {
      const payload = {
        region: region
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpStates', payload)
        .then(response => {
          if (response.status === 200) {
            this.rfpStatesData = response.data.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectedRfpState(flag) {
      this.showOpenRfpStates = flag;
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: 446
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    costCentreModal(flag) {
      this.showOpenCostCentreModal = flag;
    },
    selectedCostCentre(flag) {
      this.showOpenCostCentreModal = flag;
    },
    getCostCentreData() {
      const payload = {
        _page: this.costCentreCurrentPage - 1,
        _limit: this.perPage
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getCostCentreData', payload)
        .then(response => {
          if (response.status === 200) {
            this.costCentreData = response.data.data.data.page;
            this.costCentreTotalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  beforeDestroy() {
    this.eventBus.$off('rfpLocationFlag');
  }
};
